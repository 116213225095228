import React, { useState } from 'react'
import Accordion from '../Accordion'
import Card from '../Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Typography from '@bit/mui-org.material-ui.typography'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import partner from '../../docs/Solar_Partner_PitchDeck.pdf'
import management from '../../docs/CloudSolar_Management_Declaration.pdf'

const operationsLink = (
  <a href={management} style={{ color: '#1957a6', fontWeight: 'bold' }}>
    {' '}
    Read about our management declaration detailing our operating standards
    here.
  </a>
)

const partnerLink = (
  <a href={partner} style={{ color: '#1957a6', fontWeight: 'bold' }}>
    {' '}
    Learn more about how to become a roof/land partner with us.
  </a>
)

const signupLink = (
  <a
    href="https://app.cloud.solar/get-started/"
    style={{ color: '#1957a6', fontWeight: 'bold' }}
  >
    clicking here
  </a>
)

const howDoIEarn = (
  <>
    <Typography variant="body1">
      CloudSolar will measure and record electricity generated by our expanding
      pool of solar panels. We have sites across Barbados and each of those
      sites will produce energy every day. We take the total energy produced
      monthly at each site (kWh) and divide by the total pool capacity installed
      (kWp). Each of our community solar panel owners will receive credits for
      their proportional share of the energy produced. The energy credits will
      be calculated using the current FIT rate and the credit will be paid out
      monthly into your Solar Credits Account. For example: In one month, the
      solar pool could generate 110 kWh per kWp installed. Each customer would
      receive their share of that multiplied by the FIT rate applicable to the
      solar panel capacity they have purchased. For example: If a customer owns
      10 panels or 2.6 kWp (DC), to get their monthly solar credits you would
      multiply 2.6 kWp x 110 kWh x 42.75 cents = $122.27 less the solar
      deductible of 20% for a net payment of $97.81. See FIT payment table
      below:
    </Typography>
    <div className="faq-table">
      <table>
        <thead>
          <tr>
            <td>Solar Purchased(kW AC)</td> <td>Rate as at Oct 1 2019</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1-10 kW (AC)</td> <td>$0.4275 per kWh</td>
          </tr>
          <tr>
            <td>15-100 kW (AC)</td> <td>$0.4475 per kWh</td>
          </tr>
          <tr>
            <td>101-250 kW (AC)</td> <td>$0.4175 per kWh</td>
          </tr>
          <tr>
            <td>251-500 kW (AC)</td> <td>$0.3825 per kWh</td>
          </tr>
          <tr>
            <td>501-1000 kW (AC)</td> <td>$0.3625 per kWh</td>
          </tr>
        </tbody>
      </table>
    </div>
  </>
)

const content = [
  {
    question: 'How can I buy a digital solar panel?',
    answer: (
      <Typography variant="body1">
        Currently you can sign up for an account by {signupLink}, select the
        number of panels rated in Kilowatt Peak (kWp) and purchase it all
        online. You can only buy a maximum of 30 panels in a digital solar
        system. Purchasers looking to buy more than 30 panels to offset their
        energy needs can be facilitated in person at our head office with a
        signed power purchase agreement (PPA).
      </Typography>
    ),
  },
  {
    question: 'What is the cost of CloudSolar?',
    answer: `The cost to purchase the output of 1 Panel or 260 watts (0.26 kWp) for 25 years varies depdending on market forces and the cost to install at each site. The price per panel decreases the more panels you buy at once. We only use tier 1 equipment when we install each of our community projects.`,
  },
  {
    question:
      'What are the returns if I purchase a traditional roof-mounted solar system?',
    answer: `This can be quite complex because it depends on lots of things. Basically, the smaller the system, the longer it takes to payback your original purchase price.

    Solar based products traditionally have a useful life of 25 years.
    
    For traditional solar, a small 1.5 kW good quality system where you sell all the power it produces will probably have paid for itself in 10 years or more. Estimating it will save you $600 per year off your power bill and cost around $6,000. Yielding between 8-12% return on investment.
    
    But in addition to the original purchase and installation costs you have ongoing costs for monitoring, maintenance & insurance, replacing inverters every 5-10 years and panel cleaning every 6 months in order to maximize your system potential.
    
    This example does not take into consideration any needed repair costs or downtime of the systems. Inverters and panels go bad sometimes, and that will diminish your returns as downtime is a fact with traditional solar installations.
    
    There is also the possibility that if you move to a new house, you will have to make another investment in a solar system, because you cannot take the existing one with you.`,
  },
  {
    question: 'What services are included in my CloudSolar purchase?',
    answer: `CloudSolar will also provide the necessary monitoring, maintenance, insurance, and inverter replacements to operate the panels in the installation. We will also ensure that all applicable codes, standards and regulatory requirements are met at the time of installation and throughout the term of the agreement. We guarantee to operate to the highest standards in ensuring that all our assets are operating with the highest efficiencies in the pool.`,
  },
  {
    question: 'Does CloudSolar own the solar assets?',
    answer: (
      <Typography variant="body1">
        CloudSolar owns all of the solar PV infrastructure assets, except the
        solar panels, which are property of our owners. Our customers are
        purchasing a digital solar agreement, which is a service contract to
        install and maintain their panels in a remotely located renewable energy
        system, which they receive the full economic benefits from the energy
        their panels produce. CloudSolar licenses its roofs, lands and other
        assets which it uses to make investments in solar installations for the
        benefit of its clients and the solar pool. Every dollar of solar we sell
        to a client, goes towards the purchase of more solar PV array systems
        across the island of Barbados, which increases our solar pool for the
        benefit of others who want to own community solar.
        <br />
        <br />
        {operationsLink}
      </Typography>
    ),
  },
  {
    question: 'How do I earn money from my purchase?',
    answer: howDoIEarn,
  },
  {
    question: 'Would it be better to put solar panels on my roof?',
    answer: `Not necessarily, because every roof is different, and a roof can only hold a limited supply of solar panels, which translates into an expensive system and a longer payback. With CloudSolar we have taken out all the guesswork from the customers' point of view to deliver the most value. With CloudSolar the old limitations and barriers of up-front installation costs, maintenance, inverter replacement, right roof orientation, roof damage, fire risk, weather damage, feed in tariff rates, movability and the list goes on, no longer apply. Our products are well suited to renters and homeowners who may never see the opportunity to own a solar system.`,
  },
  {
    question:
      "How does CloudSolar protect my solar assets on another person's roof/land?",
    answer: (
      <Typography variant="body1">
        CloudSolar sets up a license agreement with the real estate your panels
        are installed on. We approach owners and license their roofs/land (which
        we pay for). This gives us the exclusive rights to use the property and
        to install our clients' solar panels. After the license ends, we hand
        back the owners' real estate asset or re-sign a new agreement. If you
        are an owner with significant roof or land we can help you generate
        solar energy and get paid for your asset.
        <br />
        <br />
        {partnerLink}
      </Typography>
    ),
  },
  {
    question:
      'Can CloudSolar help people with existing solar installations save even more money?',
    answer: `Yes. Even if someone has existing solar panels on their roof they can benefit further by purchasing our digital solar panels with no limits.`,
  },
  {
    question: 'What happens after I make my purchase?',
    answer: `1. After you purchase your digital solar panels, your remotely installed system will go live after we verify your payment terms.
    2. Earnings will start to accumulate on a monthly basis based on the overall generation of the solar pool production, and earnings in proportion to your kWp owned in the solar pool.
    3. Your earnings from your solar generated will be calculated and deposited into your Solar Credits Account.
    4. All solar earnings are paid out automatically on a monthly basis from the Solar Credits Account in the form of a direct deposit.`,
  },
  {
    question: 'How safe is my purchase with CloudSolar?',
    answer: `CloudSolar safeguards all of its assets and its clients' assets through progressive insurance policies that protect the business from likely disasters and unforeseen circumstances. Our insurance policies are taken out with A-Grade insurance companies with global reinsurance treaties. Solar is generally considered one of the safest, low-risk investments in the market today with great long-term growth potential. Our company is also backed by one of the largest private sector groups in Barbados, with a presence in the local economy that spans more than half a century.`,
  },
  {
    question: 'Do you offer payment options?',
    answer: `We do not offer payment options to clients. Check with your local bank and/or credit union on their offers for renewable energy loans.`,
  },
]

export default function InvestingQuestions() {
  const [activeEventKey, setActiveEventKey] = useState(0)

  return (
    <>
      <Accordion activeEventKey={activeEventKey} onToggle={setActiveEventKey}>
        {content.map(({ question, answer }, index) => (
          <Card key={index}>
            <Accordion.Toggle
              element={Card.Header}
              eventKey={index}
              className={
                activeEventKey === index ? 'questions-header-active' : ''
              }
            >
              <h1
                className={`questions ${
                  activeEventKey === index ? 'questions-header-text-active' : ''
                }`}
              >
                {question}
              </h1>
              {activeEventKey !== index && (
                <span style={{ marginTop: 12 }}>
                  <FontAwesomeIcon icon={faChevronDown} className="qIcon" />
                </span>
              )}

              {activeEventKey === index && (
                <span style={{ marginTop: 12 }}>
                  <FontAwesomeIcon
                    icon={faChevronUp}
                    className="qIcon"
                    style={{ color: '#fff' }}
                  />
                </span>
              )}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={index} element={Card.Body}>
              {answer}
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    </>
  )
}
